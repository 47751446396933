@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@700;900&family=Poppins:wght@200;400;600;800&family=Sora:wght@800&display=swap");

body,
html {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    background-color: #121212;
    color: #fff;
    font-family: "Poppins", sans-serif;
}

/* Scroll Bar  */

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #121212;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(76, 75, 75);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #121212;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.colored-section {
    background-color: #40375c;
}

section {
    padding: 0% 10% 5%;
}

.link-hover:hover {
    color: #4786e5;
}

/* Home Section */

.home-section {
    padding: 4% 12% 5%;
}

/* Navigation Section */

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-family: "Sora", sans-serif;
    text-decoration: none;
    color: #fff;
    margin: 10px;
    font-size: 2.5rem;
    letter-spacing: 2px;
}

.nav-ul {
    list-style: none;
    font-size: 1.2rem;
    display: flex;
}

.nav-item {
    text-align: center;
    margin-left: 20px;
}

.nav-link {
    font-family: "Lato", sans-serif;
    text-decoration: none;
    color: #fff;
}

#nav-check {
    display: none;
}

.nav-check-btn {
    font-size: 1.2rem;
    cursor: pointer;
    display: none;
}

/* Home Section */

.home {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.heading {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 7rem;
    margin-bottom: 0;
}

.header-with-links {
    display: flex;
    flex-direction: column;
}

.header-social-links {
    display: flex;
}

.header-social-item {
    margin-top: 10px;
    margin-right: 20px;
    font-size: 2.5rem;
}

.header-link {
    color: #fff;
}

.bitmoji-img {
    width: 405px;
    height: 409px;
}

/* About Section */

.about {
    padding: 2% 20% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.title {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
}

.sub-title {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

article {
    font-size: 1.8rem;
    margin: 10px;
    line-height: 1.5;
}

.resume-btn {
    margin: 40px auto auto;
}

.bold {
    font-weight: 800;
}

/* Skills Section */

.skills {
    padding: 2% 15% 2%;
}

.skills-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-bottom: 100px;
}

.skill-item {
    flex: 0 0 33.333333%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.skill-img {
    width: 70px;
    height: 70px;
}

/* Projects Section */

.projects {
    padding: 0% 12% 6%;
}


.project-main-title {
    padding-bottom: 20px;
}

.projects-list {
    display: flex;
    flex-direction: column;
}

.project-item {
    margin-top: 10px;
    display: flex;
}

.project-img {
    height: auto;
    width: 50%;
    border-radius: 9px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.project-details {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
}

.project-title {
    align-self: flex-start;
    font-size: 2rem;
    margin: 0;
}

.project-links {
    display: flex;
}

.project-link-items {
    color: #fff;
    font-size: 1.8rem;
}

.project-link-items + .project-link-items {
    margin-left: 20px;
}

.project-desc {
    font-size: 1.2rem;
}

.project-item + .project-item {
    margin-top: 100px;
}

.projects-right-align {
    flex-direction: row-reverse;
}

.projects-right-align .project-details {
    margin-left: auto;
    margin-right: 20px;
}

/* Contacts Section */

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-btn {
    background: #75acff;
    background-image: -webkit-linear-gradient(top, #75acff, #4786e5);
    background-image: -moz-linear-gradient(top, #75acff, #4786e5);
    background-image: -ms-linear-gradient(top, #75acff, #4786e5);
    background-image: -o-linear-gradient(top, #75acff, #4786e5);
    background-image: linear-gradient(to bottom, #75acff, #4786e5);
    -webkit-border-radius: 13;
    -moz-border-radius: 13;
    border-radius: 13px;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 16px 10px 16px;
    text-decoration: none;
}

.contact-btn:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
}

/* Footer Section */

footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.footer-item {
    font-size: 2.5rem;
    margin-left: 20px;
    margin-bottom: 0;
}

.footer-link {
    color: #fff;
}

.copyright {
    text-align: center;
    font-weight: 200;
}

/* Button Generator */

.btn {
    background: #ac90c1;
    background-image: -webkit-linear-gradient(top, #ac90c1, #ad5beb);
    background-image: -moz-linear-gradient(top, #ac90c1, #ad5beb);
    background-image: -ms-linear-gradient(top, #ac90c1, #ad5beb);
    background-image: -o-linear-gradient(top, #ac90c1, #ad5beb);
    background-image: linear-gradient(to bottom, #ac90c1, #ad5beb);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 6px;
    text-shadow: 1px 1px 3px #c58ccf;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 17px 10px 17px;
    text-decoration: none;
}

.btn:hover {
    background: #b5099e;
    background-image: -webkit-linear-gradient(top, #b5099e, #e34fcf);
    background-image: -moz-linear-gradient(top, #b5099e, #e34fcf);
    background-image: -ms-linear-gradient(top, #b5099e, #e34fcf);
    background-image: -o-linear-gradient(top, #b5099e, #e34fcf);
    background-image: linear-gradient(to bottom, #b5099e, #e34fcf);
    text-decoration: none;
}

/*  Image Hover */

.grow-img {
    transition: 1s ease;
}

.grow-img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

/* Image Shake */

.shake-img:hover {
    animation: horizontal-shaking 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes horizontal-shaking {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(4px);
    }
    50% {
        transform: translateX(-4px);
    }
    75% {
        transform: translateX(4px);
    }
    100% {
        transform: translateX(0);
    }
}

/* Media Query for Smartphones */

@media (max-width: 768px) {
    body {
        text-align: center;
    }
    .nav-check-container {
        display: inline-block;
    }

    .logo {
        font-size: 1.2rem;
    }

    /* Phone Navbar Section */

    .nav-check-btn {
        display: block;
    }

    nav {
        flex-wrap: wrap;
    }

    .nav-ul {
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 75vh;
        left: -100%;
        top: 60px;
        border-radius: 8px;
        transition: all .4s;
    }

    .nav-item {
        margin: 40px 0px;
        margin-left: -20px;
        line-height: 30px;
        font-size: 1.4rem;
    }

    .nav-item:first-child {
        margin-top: 80px;
    }

    #nav-check:checked {
        color: #4786e5;
    }

    #nav-check:checked ~ ul{
        position: relative;
        display: flex;
        left: 0;
        top: 0;
        background-color: #40375c;
    }

    /* nav:has(#nav-check:checked) {
        background-color: #40375c;
    } */

    /* Phone Home Section */

    .home-section {
        padding: 5% 4% 5%;
    }

    .home {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        padding: 0% 5% 5%;
        padding-bottom: 20px;
    }

    .heading {
        font-size: 4rem;
        text-align: center;
    }

    .header-with-links {
        justify-content: center;
        align-items: center;
        padding: 4% 12% 0%;
    }

    .header-social-item {
        font-size: 2.2rem;
        margin-bottom: 0;
    }

    .bitmoji-img {
        width: 205px;
        height: 209px;
    }

    .title {
        font-size: 3rem;
    }

    /* Phone About Section */

    .about {
        padding: 2% 14% 8%;
    }

    article {
        font-size: 1.2rem;
    }

    section {
        padding: 0% 10% 5%;
    }

    .skill-item {
        flex-basis: 50%;
    }

    .projects {
        padding: 0% 12% 8%;
    }

    .project-item {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .project-item + .project-item {
        margin-top: 70px;
    }

    .project-details {
        margin-left: auto;
        justify-content: center;
        align-items: center;
    }

    .project-img {
        height: auto;
        width: 80%;
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .project-title {
        align-self: center;
    }

    .contact {
        margin-bottom: 20px;
    }
}
